.background {
	background-image: url("../media/images/Banner-2.png");
	height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media screen and (max-width: 900px) {
	.background {
		height: auto;
		padding-bottom: 2rem;
	}
}

.innerDiv {
	display: flex;
	align-items: center;
	position: relative;
}
.btns {
	display: flex;
	flex-direction: column;
	width: 20%;
	position: relative;
}
.repeatBg {
	background-image: url("../media/images/Rectangle\ 413.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: rgba(0, 0, 0, 0.886);
}
.about {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding-bottom: 20px;
}
@media screen and (max-width: 900px) {
	.title {
		background-image: linear-gradient(
			151.49deg,
			#95ffd3 17.2%,
			#019c1a 85.25%
		);
		-webkit-text-fill-color: transparent;
		background-clip: text;
		font-size: 50px !important;
		text-align: center;
	}
}
@media screen and (min-width: 900px) {
	.title {
		background-image: linear-gradient(
			151.49deg,
			#95ffd3 17.2%,
			#019c1a 85.25%
		);
		-webkit-text-fill-color: transparent;
		background-clip: text;
		font-size: 70px !important;
		text-align: center;
	}
}
@media screen and (max-width: 1200px) {
	.tokennomics1 {
		order: 2;
	}
	.tokennomics2 {
		order: 1;
	}
	.tokennomics3 {
		order: 3;
	}
}
.aboutUs {
	width: 50%;
}
.buy {
	background-image: url("../media/images/Rectangle\ 409.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 5%;
}
.token {
	background-image: url("../media/images/Group.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 5%;
}
.innerCircle {
	width: 50%;
	margin: auto;
}
.innerCircle {
	display: flex;
	justify-content: center;
	align-items: center;
}
.innerCircle img {
	width: 10%;
	height: 20vh;
	margin-top: 10%;
}
.outterMeme {
	display: flex;
	justify-content: flex-end;
}
.outterMeme img {
	width: 20%;
}
.roadMap {
	background-color: black;
	padding-top: 50px;
}
.contact {
	background-color: black;
	text-align: center;
}
.swapWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 50px;
	padding-bottom: 50px;
}
.btn {
	text-align: center;
	width: 100%;
	padding-bottom: 8px;
}
@media screen and (max-width: 900px) {
	.img {
		width: 100%;
		margin-bottom: 50px;
	}
	.logo {
		width: 40%;
	}
	.innerDiv {
		flex-direction: column;
	}
	.innerDiv,
	.btns {
		text-align: center;
		top: 5%;
	}
	.button {
		margin: auto;
		margin: 10px;
	}
	.iconButton {
		display: none;
	}
	.steps {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.about {
		flex-direction: column;
		background-color: black;
	}
	.aboutUs {
		width: 80%;
	}
	.meme {
		width: 80%;
		margin: 5%;
	}
	.btns {
		width: 60%;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -15%;
	}
	.memeClass {
		display: none;
	}
}
