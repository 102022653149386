@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=CHEESEBURGA:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bahiana:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bahiana&display=swap');
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-cheeseburga: CHEESEBURGA;
  --font: Poppins;
  --font-bahiana: Bahiana;
  --font-roboto: Roboto;
  --font-inherit: inherit;
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-2xl-8: 21.8px;
  --font-size: 36px;
  --font-size-109xl: 128px;
  --font1-size: 16px;
  --font2-size: 24px;
  --font-size-81xl: 100px;
  --font-size-22xl-3: 41.3px;
  --font-size-8xl-6: 27.6px;
  --font-size-57xl: 76px;
  --font3-size: 48px;
  --font-size-xl: 20px;

  /* Colors */
  --color-gray-100: #151515;
  --gray-1: #0e101c;
  --color-gray-200: rgba(0, 0, 0, 0.39);
  --color-gray-300: rgba(255, 255, 255, 0.7);
  --white: #fff;
  --color: #009c19;
  --color-lightgreen: #acffb9;
  --color-limegreen: #2dda11;
  --color-darkslategray: #2e2e2e;
  --color-mediumspringgreen: #00ff94;
  --color-palegreen: #8bff7a;

  /* Gaps */
  --gap-6xs: 7px;
  --gap-2xl: 21px;
  --gap-18xl: 37px;

  /* Paddings */
  --padding-2xl: 21px;
  --padding-8xl: 27px;
  --padding-40xl-1: 59.1px;
  --padding-26xl: 45px;
}

@font-face {
  font-family: "Cheeseburga"; 
  src: local("CHEESEBURGA"),
    url("./fonts/CHEESEBURGA.ttf") format("truetype");
}
